<template>
    <section class="megamenu">
        <div class="mobile-content">
            <Collapsible :id="findUniqueId('collapsible')" class="collapsible">
                <template #header>
                    <span>{{ slice.primary.dropdownText }}</span>
                </template>
                <template #content>
                    <PrismicRichText :field="slice.primary.title" class="title" />

                    <ul class="menu-items">
                        <li v-for="(item, index) in slice.items" :key="`menu-item${index}`" class="menu-item-wrapper">
                            <PrismicLink
                                v-if="isLink('_', item.link)"
                                :field="item.link"
                                :class="{'menu-item': true, active: index === currentItem}"
                                :link-resolver="linkResolver"
                            >
                                <OptimizedImage :prismic-image="item.icon" class="icon" height="24" width="24" />
                                <div class="item-content">
                                    <PrismicRichText :field="item.title" class="item-title" />
                                    <PrismicRichText v-if="isFilled.richText(item.subtitle)" :field="item.subtitle" class="item-subtitle" />
                                </div>
                            </PrismicLink>
                            <button
                                v-else
                                :aria-expanded="isLink('_', item.link) ? undefined : index === currentItem"
                                :aria-haspopup="isLink('_', item.link) ? undefined : 'menu'"
                                :class="{'menu-item': true, active: index === currentItem}"
                                @click="() => setCurrentItem(index)"
                            >
                                <OptimizedImage :prismic-image="item.icon" class="icon" height="24" width="24" />
                                <div class="item-content">
                                    <PrismicRichText :field="item.title" class="item-title" />
                                    <PrismicRichText v-if="isFilled.richText(item.subtitle)" :field="item.subtitle" class="item-subtitle" />
                                </div>
                            </button>
                        </li>
                    </ul>
                </template>
            </Collapsible>

            <div :class="{'subcategory-dialog': true, open: currentItem !== null}">
                <div class="subcategory-parent-header">
                    <VJoyButton icon="arrow-left" size="small" circle variant="ghost" @click="() => setCurrentItem(null)" />
                    <PrismicRichText v-if="currentItem !== null" :field="slice.items[currentItem].title" class="subcategory-parent-title" />
                </div>
                <div class="subcategory-content">
                    <SliceZone :slices="currentSubcategoriesSlices" :components="components" />
                </div>
                <div class="user-controls">
                    <SliceZone v-if="headerUserSlice" :slices="[headerUserSlice]" :components="components" />
                    <CountrySelectionDialogTrigger :country-slice="countrySlice" :locale="locale!" />
                </div>
            </div>
        </div>

        <div class="desktop-content dropdown">
            <Dropdown :id="findUniqueId('dropdown')" menu-class="menu">
                <template #header>
                    {{ slice.primary.dropdownText }}

                    <VJoyIcon name="chevron-down" class="chevron" size="xxsmall" />
                </template>
                <template #content>
                    <div id="dropdown-header-label">
                        <PrismicRichText :field="slice.primary.title" class="title" />
                    </div>
                    <div class="menu-content">
                        <ul class="menu-items">
                            <li
                                v-for="(item, index) in slice.items"
                                :key="`menu-item${index}`"
                                class="menu-item-wrapper"
                                @mouseenter="() => setCurrentItem(index)"
                            >
                                <PrismicLink
                                    v-if="isLink('_', item.link)"
                                    :field="item.link"
                                    :class="{'menu-item': true, active: index === currentItem}"
                                    :link-resolver="linkResolver"
                                >
                                    <OptimizedImage :prismic-image="item.icon" class="icon" height="24" width="24" sizes="small:24px" />
                                    <div class="item-content">
                                        <PrismicRichText :field="item.title" class="item-title" />
                                        <PrismicRichText v-if="isFilled.richText(item.subtitle)" :field="item.subtitle" class="item-subtitle" />
                                    </div>
                                </PrismicLink>
                                <button
                                    v-else
                                    :aria-expanded="isLink('_', item.link) ? undefined : index === currentItem"
                                    :aria-haspopup="isLink('_', item.link) ? undefined : 'menu'"
                                    :class="{'menu-item': true, active: index === currentItem}"
                                >
                                    <OptimizedImage :prismic-image="item.icon" class="icon" height="24" width="24" sizes="small:24px" />
                                    <div class="item-content">
                                        <PrismicRichText :field="item.title" class="item-title" />
                                        <PrismicRichText v-if="isFilled.richText(item.subtitle)" :field="item.subtitle" class="item-subtitle" />
                                    </div>
                                </button>
                            </li>
                        </ul>
                        <div v-if="currentSubcategoriesSlices.length > 0" class="menu-subcategories">
                            <SliceZone :slices="currentSubcategoriesSlices" :components="components" />
                        </div>
                    </div>
                </template>
            </Dropdown>
        </div>
    </section>
</template>

<script setup lang="ts">
    import type {Ref} from 'vue';
    import {computed, inject, onMounted, onBeforeUnmount, ref} from 'vue';
    import {VJoyIcon, VJoyButton} from '@maltjoy/core-vue';
    import {getSliceComponentProps, PrismicLink, PrismicRichText, SliceZone} from '@prismicio/vue';
    import type {SharedSlice} from '@prismicio/types';
    import {useAsyncData} from '#app';
    import {useLocale} from '#imports';
    import {navbarUnloggedKeys} from '@navbar-unlogged/keys';
    import type {MegamenuSlice, MegamenuSubcategorySlice} from '@prismicio-types';
    import {usePrismicFieldHelpers} from '@navbar-unlogged/composables';
    import {linkResolver} from '@navbar-unlogged/helpers';
    import {components} from '@navbar-unlogged/slices';
    import Collapsible from '@navbar-unlogged/components/Collapsible.vue';
    import OptimizedImage from '@navbar-unlogged/components/OptimizedImage.vue';
    import CountrySelectionDialogTrigger from '@navbar-unlogged/components/CountrySelectionDialogTrigger.vue';
    import Dropdown from '@navbar-unlogged/components/Dropdown.vue';
    import {isFilled} from '@prismicio/helpers';

    const props = defineProps(getSliceComponentProps<MegamenuSlice>());

    const isMobile = computed(() => (props.context as {mobile: boolean} | undefined)?.mobile);

    const {locale} = useLocale();

    const currentItem: Ref<number | null> = ref(null);
    const currentSubcategoriesSlices = ref<MegamenuSubcategorySlice[]>([]);
    const isSubcategoryModalOpen = ref(false);

    const navbar = inject(navbarUnloggedKeys.PRISMIC_DOCUMENT);
    const megamenuSubcategoriesSlices = navbar?.data?.slices?.filter((slice: SharedSlice) => slice.slice_type === 'megamenu_subcategory');
    const headerUserSlice = navbar?.data?.slices?.find((slice: SharedSlice) => slice.slice_type === 'header_user_slice');
    const countrySlice = navbar?.data?.slices?.find((slice: SharedSlice) => slice.slice_type === 'country_slice');

    function findUniqueId(componentType: 'collapsible' | 'dropdown') {
        return `${props.slice.id!}-${componentType}-${isMobile.value ? 'mobile' : 'desktop'}`;
    }

    const {isLink} = usePrismicFieldHelpers();

    function setCurrentItem(index: number | null) {
        currentItem.value = index;
        if (index !== null) {
            isSubcategoryModalOpen.value = true;
            currentSubcategoriesSlices.value = [
                props.slice.items[index].leftSubcategoryUid,
                props.slice.items[index].middleSubcategoryUid,
                props.slice.items[index].rightSubcategoryUid,
            ]
                .map((uid: string | null) =>
                    uid ? megamenuSubcategoriesSlices?.find((slice: MegamenuSubcategorySlice) => slice.primary.uid === uid) : undefined,
                )
                .filter((slice): slice is MegamenuSubcategorySlice => slice !== undefined);
        } else {
            currentSubcategoriesSlices.value = [];
            isSubcategoryModalOpen.value = false;
        }
    }

    function closeMegamenu() {
        setCurrentItem(null);
        isSubcategoryModalOpen.value = false;
    }

    onMounted(() => {
        window.addEventListener('Navigation', closeMegamenu);
    });

    onBeforeUnmount(() => {
        window.removeEventListener('Navigation', closeMegamenu);
    });
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;
    @use '@navbar-unlogged/scss/variables.scss' as vars;

    ul {
        padding: 0;
        margin: 0;
    }

    a {
        text-decoration: none;
    }

    .megamenu {
        font-family: var(--joy-font-family);
        display: flex;
        align-items: center;

        .mobile-content {
            display: none;
            @include mq.screen_md {
                display: block;
            }
        }

        .desktop-content {
            @include mq.screen_md {
                display: none;
            }
        }

        @include mq.screen_md {
            width: 100%;
            display: block;
            border-top: solid 1px var(--joy-color-neutral-30);
            &:first-child {
                border-top: none;
            }
        }

        .title > :deep(h2) {
            font-size: var(--joy-font-size-primary-800);
            font-weight: bold;
            color: var(--joy-color-neutral-60);
            margin-bottom: 24px;

            @include mq.screen_md {
                font-size: 18px;
                margin-bottom: 0;
            }
        }

        .dropdown {
            :deep(.dropdown-header) {
                width: auto;
                color: var(--joy-color-neutral-60);
                padding: 6px;
                font-size: var(--joy-font-size-primary-300);
                user-select: none;

                transition: all var(--joy-transition-duration-default) var(--joy-transition-timing-function);

                .chevron {
                    position: relative;
                    top: 2px;
                    margin-left: var(--joy-core-spacing-1);
                    transition: all var(--joy-transition-duration-default) var(--joy-transition-timing-function);
                }

                &[aria-expanded='true'] {
                    color: var(--joy-color-secondary-30) !important;
                    text-shadow: 0.05ex 0px 0px var(--joy-color-secondary-30), 0px 0.05ex 0px var(--joy-color-secondary-30);
                    .chevron {
                        transform: rotate(180deg);
                    }
                }

                &:hover {
                    color: var(--joy-color-secondary-30) !important;
                }
            }

            :deep(.dropdown-content) {
                padding: var(--joy-core-spacing-8);
                border-radius: var(--joy-core-radius-4);
            }
        }

        @media (max-height: 735px) {
            :deep(.dropdown-content) {
                padding: var(--joy-core-spacing-6) !important;
            }
        }

        .menu-items {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            gap: 20px;
            list-style: none;

            @include mq.screen_md {
                gap: 0;
            }
        }

        @media (max-height: 735px) {
            .menu-items {
                gap: 0;
            }

            .menu-item {
                gap: 10px !important;
            }
        }

        .menu-item-wrapper {
            @include mq.screen_md {
                border-bottom: solid 1px var(--joy-color-neutral-30);
                &:last-child {
                    border-bottom: none;
                }
            }
        }

        .menu-item {
            display: flex;
            border-radius: 8px;
            padding: 18px 12px;
            gap: 20px;
            color: var(--joy-color-neutral-50);
            font-size: var(--joy-font-size-primary-600);
            border: none;
            transition: all var(--joy-transition-duration-default) var(--joy-transition-timing-function);
            width: 375px;
            box-sizing: border-box;
            align-items: center;

            @include mq.screen_md {
                padding: 32px 0px;
                width: 100%;
            }

            &.active {
                background-color: var(--joy-color-secondary-10);
                .item-subtitle {
                    color: var(--joy-color-neutral-50);
                }
            }
        }

        .item-content {
            @include vars.flex-column-start;
            text-align: left;
            justify-content: center;
        }

        .item-title {
            :deep(*) {
                font-weight: var(--joy-font-weight-bold);
                color: var(--joy-color-neutral-60);
            }
        }

        .item-subtitle {
            margin-top: var(--joy-core-spacing-4) !important;

            color: var(--joy-color-neutral-50);
            font-size: var(--joy-font-size-primary-400);
        }

        @media (max-height: 735px) {
            .item-subtitle {
                font-size: var(--joy-font-size-primary-300);
            }
            .item-title {
                font-size: var(--joy-font-size-primary-400);
            }
        }

        .icon {
            margin: 12px;
            width: 24px;
            height: 24px;
            object-fit: contain;
        }

        :deep(.menu) {
            width: max-content;
            border: none;
            transform: none !important;
            left: -122px;

            .menu-content {
                display: flex;
                gap: 48px;
            }
        }

        .menu-item-wrapper > :deep(p) {
            padding: 0;
        }

        :deep(.menu-subcategories) {
            padding-left: 56px;
            margin: 12px 0px;
            border-left: solid 1px var(--joy-color-neutral-30);
            display: flex;
            gap: 48px;
        }

        .collapsible > :deep(button) {
            padding: 32px 0px;
            font-size: var(--joy-font-size-primary-600);
            color: var(--joy-color-neutral-50);
            width: 100%;

            &[aria-expanded='true'] {
                color: var(--joy-color-secondary-30) !important;
                font-weight: bold;
            }
        }
    }

    .subcategory-dialog {
        position: fixed;
        top: var(--header-height);
        bottom: 0;
        left: 0;
        right: 0;
        display: none;
        opacity: 0;
        background-color: white;
        padding: 10px 20px 40px;
        z-index: var(--joy-core-z-index-dialog);
        overflow: auto;

        &.open {
            display: flex;
            flex-direction: column;
            opacity: 1;
            animation: fadeIn var(--joy-transition-duration-default);
        }

        .subcategory-content {
            flex-grow: 1;
        }

        .subcategory-parent-header {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: var(--joy-color-secondary-30) !important;
            font-size: var(--joy-font-size-primary-600);
            margin: 32px 0px;
            font-weight: var(--joy-font-weight-bold);
            text-align: center;
            width: 100%;

            :deep(button) {
                border: none;
                padding: 2px;
            }

            .subcategory-parent-title {
                text-align: center;
                flex-grow: 1;
                margin-right: 24px;
                :deep(*) {
                    color: var(--joy-color-secondary-30);
                }
            }

            .arrow {
                margin: 0;
            }
        }
    }
</style>
